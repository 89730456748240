import {axiosBase} from "../constants/axiosBase";

export type ChangeIntegrationStatusType = {
    id?: number
    status: string
}

export const changeIntegrationStatus = async (data:ChangeIntegrationStatusType) => {
    const response = await axiosBase.post('/update-transfer-status/', data)
    return response.status
}
