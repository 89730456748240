import React, { ChangeEvent, useContext, useState, Component } from "react"
import {Route} from 'react-router-dom'
import { GlobalContext } from "./context/GlobalContext"
import { FrontPage } from "./pages/FrontPage"
import { LoginPage } from "./pages/LoginPage"
import { RegistrationPage } from "./pages/RegistrationPage"
import { QuestionsPage } from "./pages/QuestionsPage"
import { PasswordPage } from "./pages/PasswordPage"
import { AboutPage } from "./pages/AboutPage"
import { ContactPage } from "./pages/ContactPage"
import { BlogerPage } from "./pages/BlogerPage"
import { AccountPage } from "./pages/AccountPage/AccountPage"
import { SettingPage } from "./pages/SettingPage/SettingPage"
import { StoryPage } from "./pages/StoryPage/StoryPage"
import { ApplicationPage } from "./pages/ApplicationPage/ApplicationPage"
import { IntegrationsListPage } from "./pages/IntegrationsListPage/IntegrationsListPage"
import { IntegrationsItemPage } from "./pages/IntegrationsItemPage/IntegrationsItemPage"
import { BlogerCatalogPage } from "./pages/BlogerCatalogPage"
import { BlogerCatalogItemPage } from "./pages/BlogerCatalogItemPage"
import { PayStoryPage } from "./pages/PayStoryPage"
import { ChatPage } from "./pages/ChatPage"
import { SellerItemPage } from "./pages/SellerItemPage"
import { DBPage } from "./pages/DBPage/DBPage"
import { VideoPage } from "./pages/VideoPage/VideoPage"
import { PrivacyPage } from "./pages/PrivacyPage"
import { UserAgreementPage } from "./pages/UserAgreementPage"
import { DashbordPage } from "./pages/DashbordPage"
import { RegistrationINNPage } from "./pages/RegistrationINNPage"
import {BloggerBalance} from "./pages/BloggerBalance/BlogerBalanse";
import {Rules} from "./pages/Rules";

export const useMyRoutes = () => {
    const auth = useContext(GlobalContext)
    return (
        <>
            <Route path={`/questions/`} element={<QuestionsPage/>}/>
            <Route path={`/about/`} element={<AboutPage/>}/>
            <Route path={`/contact/`} element={<ContactPage/>}/>
            <Route path={`/bloger/`} element={<BlogerPage/>}/>


            <Route path={`/blogers/`} element={<BlogerPage/>}/>


            <Route path={`/privacy/`} element={<PrivacyPage/>}/>
            <Route path={`/rules/`} element={<Rules/>}/>
            <Route path={`/user-agreement/`} element={<UserAgreementPage/>}/>
            <Route path={`/dashbord/`} element={<DashbordPage/>}/>
            {
                auth.user.isAuthenticated ? (
                    <>
                        <Route path={`/`} element={<AccountPage/>}/>
                        <Route path={`/account/`} element={<AccountPage/>}/>
                        <Route path={`/setting/`} element={<SettingPage/>}/>
                        <Route path={`/story/`} element={<StoryPage/>}/>
                        <Route path={`/chat/`} element={<ChatPage/>}/>
                        <Route path={`/integration/:id/`} element={<IntegrationsItemPage/>}/>
                        <Route path={`/data-base/`} element={<DBPage/>}/>
                        <Route path={`/video/`} element={<VideoPage/>}/>
                        <Route path={`/seller/:id/`} element={<SellerItemPage/>}/>
                        <Route path={`/catalog/:id/`} element={<BlogerCatalogItemPage/>}/>
                        {
                            auth.user.userType == "bloger" ? (
                                <>
                                    <Route path={`/application/`} element={<ApplicationPage/>}/>
                                    <Route path={`/integrations-list/`} element={<IntegrationsListPage/>}/>
                                    <Route path={`/blogger-balance/`} element={<BloggerBalance/>}/>
                                </>
                            ):(
                                <>
                                    <Route path={`/pay-story/`} element={<PayStoryPage/>}/>
                                    <Route path={`/catalog/`} element={<BlogerCatalogPage/>}/>
                                </>
                            )
                        }
                    </>
                ):(
                    <>
                        <Route path={`/`} element={<FrontPage/>}/>
                        <Route path={`/login/`} element={<LoginPage/>}/>

                        <Route path={`/registration/`} element={<RegistrationPage/>}/>
                        <Route path={`/registrations/`} element={<RegistrationINNPage/>}/>


                        <Route path={`/password/`} element={<PasswordPage/>}/>

                    </>
                )
            }
        </>
    )
}
