import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { Link } from "react-router-dom";
import ym from "react-yandex-metrika";

export const RegistrationPage = () => {
    const auth = useContext(GlobalContext)
    const [load, setLoad] = useState(true)
    const [popupType, setPopupType] = useState("")
    const [popupError, setPopupError] = useState(true)
    const [form, setForm] = useState({
        name: "",
        email: "",
        phone: "",
        pass: "",
        cPass: "",
        userType: "bloger",
        regLink: "",
        tglogin: ""
    })

    useEffect(()=>{
        if(localStorage.getItem("userBlogerFlag")){
            window.location.replace("/registrations/")
        }
    },[])




    const changeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setForm({ ...form, [event.target.name]: event.target.value })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if(load){
            setLoad(false)
            auth.user.register(
                form.name,
                form.email.toLowerCase(),
                form.phone,
                form.pass,
                form.cPass,
                form.userType,
                form.regLink,
                form.tglogin
            ).then(function(response){
                console.log(response)
                ym('reachGoal','registration_successfully')
                setPopupType(() => response.msg)
                setPopupError(response.error)
            }).catch(function(error){
                alert("Ошибка")
            })
        }
    }

    return(
        <>
            <section className="form-item">
                <div className="content">
                    <div className="grid">
                        <div className="form-item__wrapper row">
                            <div className="col-md-6">
                                <div className="grid">
                                    <div>
                                        <h1>Создать аккаунт</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <form onSubmit={handleSubmit}>
                                    <label>
                                        <input type="text" placeholder="Имя" required name="name" value={form.name} onChange={changeHandler}  />
                                    </label>
                                    <label>
                                        <input type="email" style={{textTransform: "lowercase"}} placeholder="E-mail" required name="email" value={form.email} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        <input type="text" minLength={10} maxLength={20} placeholder="Номер телефона" required name="phone" value={form.phone} onChange={changeHandler} />
                                    </label>

                                    <label>
                                        <input type="text" placeholder="Ник в telegram" required name="tglogin" value={form.tglogin} onChange={changeHandler} />
                                    </label>

                                    <label>
                                        <span
                                            style={{
                                                textAlign: "left",
                                                fontSize: "12px",
                                                lineHeight: "1.25",
                                                marginTop: "-5px"
                                            }}
                                        >
                                            Ваш пароль должен содержать не менее восьми (8) символов в том числе: по крайней мере один (1) буквы в верхнем регистре
                                        </span>
                                        <input type="password" placeholder="Пароль" required name="pass" value={form.pass} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        <input type="password" placeholder="Подтвердите пароль" required name="cPass" value={form.cPass} onChange={changeHandler} />
                                    </label>
                                    <label>
                                        <select defaultValue='bloger' onChange={(e)=>{
                                            setForm({ ...form, userType: e.target.value })
                                        }} >
                                        <option value="seller">Рекламодатель</option>
                                            <option value="bloger">Блогер</option>
                                        </select>
                                    </label>
                                    {
                                        form.userType == "bloger" ? (
                                            <label>
                                                <input type="text" placeholder="Ссылка на соцсеть" required name="regLink" value={form.regLink} onChange={changeHandler} />
                                            </label>
                                        ):""
                                    }

                                    <button
                                        onClick={()=>{
                                        }}
                                    >
                                        Зарегистрироваться
                                    </button>
                                    <p className="form__new-account reg-text">
                                        *Отправляя данные, вы соглашаетесь с <Link to={"/privacy/"} target="_blank">политикой конфиденциальности</Link>
                                    </p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                popupType != "" ? (
                    <div className="popup-msg">
                        <div className="popup-msg__body">
                            <h3>{popupType}</h3>
                            {
                                popupError ? (
                                    <div className="popup-msg__link" onClick={()=>{
                                        setLoad(true)
                                        setPopupType("")
                                    }}>
                                        Продолжить
                                    </div>
                                ):(
                                    <Link to='/login/' className="popup-msg__link" >
                                        Войти
                                    </Link>
                                )
                            }
                        </div>
                    </div>
                ):""
            }
        </>
    )
}
