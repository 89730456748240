import React, { useRef, useContext, useState, useEffect } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { Link, useParams } from "react-router-dom";
import socInstagram from '../image/soc-instagram.svg'
import socYoutube from '../image/soc-youtube.svg'
import socVk from '../image/soc-vk.svg'
import socRutube from '../image/soc-rutube.svg'
import socTelegram from '../image/soc-telegram.svg'
import howWorkD2 from '../image/how-work_d2.png'
import confI1 from '../image/conf_i1.svg'
import confI2 from '../image/conf_i2.svg'
import confI3 from '../image/conf_i3.svg'
import {BlogerCatalogItem, host} from "../classes"
import { Form } from "../component/Form"
import axios from "axios"
import { Loader } from "../component/Loader"


export const BlogerCatalogItemPage = () => {
    const auth = useContext(GlobalContext)

    interface IntegrationInterface {
        title: string
        pk: string
    }

    const { id } = useParams();
    const [load, setLoad] = useState(true)
    const [catalog, setCatalog] = useState(new BlogerCatalogItem(id ? id : "0"))
    const [showText, setShowText] = useState(false)
    const [showPopup, setShowPopup] = useState(false)
    const [reviewsType, setReviewsType] = useState("plus")
    const [popupType, setPopupType] = useState("")
    const [popupError, setPopupError] = useState(true)
    const [showFormat, setShowFormat] = useState(false)
    const [form, setForm] = useState({
        integration: "",
        type: "",
        format1: false,
        format2: false,
        format3: false,
        format4: false,
        format5: false,
    })

    const myRefNotification = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const checkIfClickedOutside = (e: any) => {
          if (showFormat && myRefNotification.current && !myRefNotification.current.contains(e.target)) {
            setShowFormat(false)
          }
        }
        document.addEventListener("mousedown", checkIfClickedOutside)
        return () => {
          document.removeEventListener("mousedown", checkIfClickedOutside)
        }
      }, [showFormat])


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setLoad(true)

        const formData = new FormData()
        formData.append("integration", form.integration)
        formData.append("bloger", catalog.pk)
        formData.append("type", `${form.format1 ? "Рекламный пост, " : "" }${form.format2 ? "Выкуп на маркетплейсе, " : "" }${form.format3 ? "Отзыв на маркетплейсе, " : "" }${form.format5 ? "Видеоотзыв на маркетплейсе " : "" }${form.format4 ? "Раздача товаров подписчикам " : "" }`)


        await axios.post(`https://api.blogerito.ru/integration-applications/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Token '+auth.user.userToken
            }
        }).then(res => {
            setLoad(false)
            setPopupType(res.data.msg)
            setPopupError(res.data.error)
            setShowPopup(false)
        }).catch(error => {
            console.log(error)
            alert("error")
            setLoad(false)
        })
    }

    const [integrationList, setIntegrationList] = useState([])
    const IntegrationList = async () => {
        setLoad(true)

        await axios.get(`${host}form-integration/`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token '+auth.user.userToken
            }
        }).then(res => {
            setLoad(false)
            setIntegrationList(res.data)
            setShowPopup(true)
        })
    }

    useEffect(()=>{
        catalog.Load(auth.user.userToken).then(function(response){
            catalog.LoadReviews(auth.user.userToken, reviewsType, "bloger").then(function(response){
                setLoad(false)
                console.log(catalog.reviews)
            }).catch(function(error){
                setLoad(false)
                alert("error")
            })
        }).catch(function(error){
            setLoad(false)
            alert("error")
        })
    },[])

    useEffect(()=>{
    },[reviewsType])

    if(load){
        return(
            <Loader />
        )
    }

    return (
        <>
        {
            catalog.blogerInfo ? (
                <section className="bloger-info">
                    <div className="content">
                        <h2 className="title ftitle">Страница блогера:</h2>
                        <div className="row">
                            <div className="col-md-3 order1">
                                <div className="bloger-info__userpic" style={{backgroundImage: `url(http://api.blogerito.ru/${catalog.blogerInfo.image})`}}></div>

                                <div className="item-cost">
                                    <p>Стоимость интеграции</p>
                                    <h3>{catalog.blogerInfo.integrationCost} <span>руб.</span></h3>

                                    {
                                        auth.user.userType == "seller" ? (
                                            <button onClick={()=>{

                                                IntegrationList()
                                            }}>
                                                Заказать интеграцию
                                            </button>
                                        ):""
                                    }
                                </div>

                            </div>
                            <div className="col-md-6 order3">
                                <div className="bloger-info__wrapper">
                                    <div className="bloger-info__status">
                                        <div className="bloger-info__prize">
                                            <div className="bloger-info__prize-wrapper">
                                                <p>{catalog.blogerInfo.integration}</p>
                                            </div>
                                        </div>
                                        <div className="bloger-info__status-text">
                                            {catalog.blogerInfo.dateActivity}
                                        </div>
                                    </div>
                                    <h2 className="bloger-info__title">
                                        {catalog.blogerInfo.name}
                                        <span> {catalog.blogerInfo.bDate}, {catalog.blogerInfo.city}</span>
                                    </h2>
                                    <p className="bloger-info__minidesc">
                                        {catalog.blogerInfo.minidesc}
                                    </p>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <p className="bloger-info__category">
                                                {catalog.blogerInfo.category}
                                            </p>
                                            <div className="catalog__soc">
                                                {
                                                    catalog.blogerInfo.instagram != null ? (
                                                        <img src={socInstagram} />
                                                    ):""
                                                }
                                                {
                                                    catalog.blogerInfo.youtube != null ? (
                                                        <img src={socYoutube} />
                                                    ):""
                                                }
                                                {
                                                    catalog.blogerInfo.vk != null ? (
                                                        <img src={socVk} />
                                                    ):""
                                                }
                                                {
                                                    catalog.blogerInfo.rutube != null ? (
                                                        <img src={socRutube} />
                                                    ):""
                                                }
                                                {
                                                    catalog.blogerInfo.telegram != null ? (
                                                        <img src={socTelegram} />
                                                    ):""
                                                }
                                            </div>
                                        </div>

                                        <div className="col-md-7">
                                            {
                                                catalog.blogerInfo.CatalogConf == true ? (
                                                    <p className="bloger-info__dop" style={{backgroundImage: `url(${confI3})`, backgroundSize:28}}>Аккаунт подтвержден</p>
                                                ):""
                                            }
                                            {
                                                catalog.blogerInfo.docConf == true ? (
                                                    <p className="bloger-info__dop" style={{backgroundImage: `url(${confI1})`}}>Документы подтверждены</p>
                                                ):""
                                            }
                                            {
                                                catalog.blogerInfo.phoneConf == true ? (
                                                    <p className="bloger-info__dop" style={{backgroundImage: `url(${confI2})`}}>Телефон подтверждены</p>
                                                ):""
                                            }
                                        </div>
                                    </div>
                                </div>


                                <div className={`bloger-info__maintext ${!showText ? "shadow":""}`}>
                                    <p>
                                        {catalog.blogerInfo.desc}
                                    </p>
                                </div>
                                {
                                    !showText ? (
                                        <button className="myprogramms__morebtnitem" onClick={()=>{
                                            setShowText(true)
                                        }}>Показать целиком</button>
                                    ):""
                                }
                            </div>
                            <div className="col-md-3 order2">
                                <div className="sub-count">
                                    <h3>
                                        {
                                            Number(catalog.blogerInfo.subscribers) < 10000 ? (
                                                Number(catalog.blogerInfo.subscribers) < 2000 ? "Обзорщик":"Наноблогер"
                                            ):"Микроблогер"
                                        }
                                    </h3>
                                </div>
                                <div className="sub-prog">
                                    <div className="sub-prog__img">
                                        <div className="one">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path d="M10.0002 0C7.41404 0 5.32045 2.0936 5.32045 4.6798C5.32045 7.26601 7.41404 9.35961 10.0002 9.35961C12.5865 9.35961 14.6801 7.26601 14.6801 4.6798C14.6801 2.0936 12.5865 0 10.0002 0ZM11.8229 9.90148H8.17759C4.50764 9.90148 1.52734 12.8818 1.52734 16.5517V19.2611C1.52734 19.6798 1.84754 20 2.26626 20H17.7342C18.153 20 18.4732 19.6798 18.4732 19.2611V16.5517C18.4732 12.8818 15.4929 9.90148 11.8229 9.90148Z" fill="#4361EE"/>
                                            </svg>
                                        </div>
                                        <div className="two">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <g clip-path="url(#clip0_121_4626)">
                                                    <path d="M16.4525 15.0375L13.26 14.2392L13.0758 13.5017C15.2783 13.1309 16.4116 12.4709 16.4616 12.4417C16.58 12.3725 16.6533 12.2467 16.6633 12.11C16.6725 11.9733 16.6116 11.8392 16.5041 11.7533C16.4892 11.7417 15 10.5117 15 6.25C15 2.65582 14.1592 0.83332 12.5 0.83332H12.2542C11.6558 0.255 11.2067 0 10 0C8.43418 0 5 1.57418 5 6.25C5 10.5117 3.51082 11.7417 3.5 11.75C3.44543 11.791 3.4017 11.8446 3.37265 11.9064C3.3436 11.9681 3.33009 12.036 3.33332 12.1042C3.33681 12.1724 3.35703 12.2387 3.3922 12.2973C3.42738 12.3559 3.47642 12.4049 3.535 12.44C3.585 12.47 4.7075 13.1333 6.92332 13.5033L6.73914 14.2391L3.54668 15.0375C1.45832 15.56 0 17.4283 0 19.5833C0 19.8133 0.18668 20 0.41668 20H19.5834C19.8134 20 20 19.8117 20 19.5817C20.0013 18.5368 19.6527 17.5216 19.0097 16.698C18.3667 15.8744 17.4664 15.2898 16.4525 15.0375Z" fill="#DD79DB"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_121_4626">
                                                        <rect width="20" height="20" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="sub-prog__progerss">
                                        <div style={{width: `${catalog.blogerInfo.men}%`}} className="width: 33%;"></div>
                                    </div>
                                </div>

                                <div className="sub-prog__rew">
                                    <div>
                                        <p>Отзывы</p>
                                    </div>
                                    <div>
                                        <div className="sub-prog__proc">
                                            <div style={{borderRight: "1.5px solid #3A0CA3"}}>{catalog.blogerInfo.goodreview}+</div>
                                            <div>{catalog.blogerInfo.badreview}-</div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            ):""
        }

        <section className="bloger-page__review">
            <div className="how-works__dots" style={{backgroundImage: `${howWorkD2}`}}>
                <div className="content">
                    <h2>Отзывы:</h2>
                    <select onChange={(e)=>{
                        setReviewsType(e.target.value)
                        catalog.reviews = []
                        catalog.reviews.shift()
                        catalog.targetPage = 1
                        setLoad(true)
                        catalog.LoadReviews(auth.user.userToken, reviewsType, "bloger").then(function(response){
                            setLoad(false)
                        }).catch(function(error){
                            alert("error")
                        })
                    }}>
                        <option value={"plus"} selected={reviewsType == "plus" ? true : false}>Положительные</option>
                        <option value={"min"} selected={reviewsType == "min" ? true : false}>Отрицательные</option>
                    </select>
                    {
                        catalog.reviews ? (
                            catalog.reviews.length ? (
                                catalog.reviews.map((item, index) => {
                                    return(
                                        <div key={index} className="bloger-page__review-item">
                                            <div className="row">
                                                <div className="col-md-2">
                                                    <div className="stars-line">
                                                        {
                                                            Number(item.estimation) >= 1 ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                    <path d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z" fill="#F8C50F"/>
                                                                </svg>
                                                            ):""
                                                        }
                                                        {
                                                            Number(item.estimation) >= 2 ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                    <path d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z" fill="#F8C50F"/>
                                                                </svg>
                                                            ):""
                                                        }
                                                        {
                                                            Number(item.estimation) >= 3 ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                    <path d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z" fill="#F8C50F"/>
                                                                </svg>
                                                            ):""
                                                        }
                                                        {
                                                            Number(item.estimation) >= 4 ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                    <path d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z" fill="#F8C50F"/>
                                                                </svg>
                                                            ):""
                                                        }
                                                        {
                                                            Number(item.estimation) >= 5 ? (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                                                                    <path d="M8.02447 0.463524C8.17415 0.00286853 8.82585 0.00287008 8.97553 0.463525L10.5206 5.21885C10.5876 5.42486 10.7795 5.56434 10.9962 5.56434H15.9962C16.4806 5.56434 16.6819 6.18415 16.2901 6.46885L12.245 9.4078C12.0697 9.53512 11.9964 9.7608 12.0633 9.96681L13.6084 14.7221C13.7581 15.1828 13.2309 15.5659 12.839 15.2812L8.79389 12.3422C8.61865 12.2149 8.38135 12.2149 8.20611 12.3422L4.16099 15.2812C3.76913 15.5659 3.24189 15.1828 3.39157 14.7221L4.93667 9.96681C5.0036 9.7608 4.93027 9.53512 4.75503 9.4078L0.709911 6.46885C0.318054 6.18415 0.519443 5.56434 1.0038 5.56434H6.00385C6.22046 5.56434 6.41244 5.42486 6.47937 5.21885L8.02447 0.463524Z" fill="#F8C50F"/>
                                                                </svg>
                                                            ):""
                                                        }
                                                    </div>
                                                    <div className="bloger-info__review-date">{item.date}</div>
                                                </div>
                                                <div className="col-md-10">
                                                    <h3>
                                                        Задание:
                                                        <span>{item.integration.title}</span>
                                                    </h3>
                                                    <p>{item.text}</p>
                                                    <div className="myprogramms__logo">
                                                        <div className="myprogramms__logo-img" style={{backgroundImage: `url(http://api.blogerito.ru/${item.integration.seller.image})`}}></div>
                                                        <div className="myprogramms__logo-content">
                                                            <div className="grid">
                                                                <div>
                                                                    <h4>
                                                                        <a href="">{item.integration.seller.name}</a>
                                                                    </h4>
                                                                    <p>{item.integration.seller.minidesc}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            ):""
                        ):""
                    }
                    {
                        catalog.countPage > catalog.targetPage ? (
                            <button className="myprogramms__morebtnitem" onClick={()=>{
                                catalog.targetPage += 1
                                setLoad(true)
                                catalog.LoadReviews(auth.user.userToken, reviewsType, "bloger").then(function(response){
                                    setLoad(false)
                                }).catch(function(error){
                                    alert("error")
                                })
                            }}>Загрузить еще +</button>
                        ):""
                    }
                </div>
            </div>
        </section>

        <Form />

        {
            showPopup ? (
                <div className="popup-order">
                    <div>
                        <div className="popup-close">
                            <svg onClick={()=>{
                                setShowPopup(false)
                            }} xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 1024 1024">
                                <path fill="#000000" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504 738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512 828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496 285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512 195.2 285.696a64 64 0 0 1 0-90.496z"/>
                            </svg>
                        </div>

                        {
                            integrationList.length ? (
                                <form onSubmit={handleSubmit}>
                                    <label>
                                        Интеграция
                                        <select required onChange={(e)=>{
                                            setForm({ ...form, "integration": e.target.value })
                                        }}>
                                            <option value="" disabled selected>Выбрать</option>
                                            {
                                                integrationList.map((item: IntegrationInterface, index)=>{
                                                    return (
                                                        <option value={item.pk}>{item.title}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </label>

                                    <div style={{fontSize: "14px"}}>
                                        Формат рекламы
                                        <div className="type-sellect">
                                            <div className="type-sellect__text" onClick={()=>{setShowFormat(!showFormat)}}>
                                                <div>
                                                    {!form.format1 && !form.format2 && !form.format3 && !form.format4 && !form.format5 ? "Выбрать" : (
                                                        <>
                                                            {form.format1 ? "Рекламный пост, " : "" }
                                                            {form.format2 ? "Выкуп на маркетплейсе, " : "" }
                                                            {form.format3 ? "Отзыв на маркетплейсе, " : "" }
                                                            {form.format5 ? "Видеоотзыв на маркетплейсе, " : "" }
                                                            {form.format4 ? "Раздача товаров подписчикам " : "" }
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            {
                                                showFormat ? (
                                                    <div ref={myRefNotification} className="type-sellect__options">
                                                        <label className="type-sellect__item">
                                                            <input type="checkbox" name="format1" checked={form.format1 ? true:false} onChange={(e)=>{
                                                                setForm({ ...form, [e.target.name]: e.target.checked })
                                                            }} />
                                                            Рекламный пост
                                                        </label>
                                                        <label className="type-sellect__item">
                                                            <input type="checkbox" name="format2" checked={form.format2 ? true:false} onChange={(e)=>{
                                                                setForm({ ...form, [e.target.name]: e.target.checked })
                                                            }} />
                                                            Выкуп на маркетплейсе
                                                        </label>
                                                        <label className="type-sellect__item">
                                                            <input type="checkbox" name="format3" checked={form.format3 ? true:false} onChange={(e)=>{
                                                                setForm({ ...form, [e.target.name]: e.target.checked })
                                                            }} />
                                                            Отзыв на маркетплейсе
                                                        </label>
                                                        <label className="type-sellect__item">
                                                            <input type="checkbox" name="format5" checked={form.format5 ? true:false} onChange={(e)=>{
                                                                setForm({ ...form, [e.target.name]: e.target.checked })
                                                            }} />
                                                            Видеоотзыв на маркетплейсе
                                                        </label>
                                                        <label className="type-sellect__item">
                                                            <input type="checkbox" name="format4" checked={form.format4 ? true:false} onChange={(e)=>{
                                                                setForm({ ...form, [e.target.name]: e.target.checked })
                                                            }} />
                                                            Раздача товаров подписчикам
                                                        </label>
                                                    </div>
                                                ):''
                                            }
                                        </div>
                                    </div>

                                    <button>Отправить</button>
                                </form>
                            ):(
                                <div className="none-integrations">
                                    <h3>Нет доступных интеграций</h3>
                                    <Link to="/account/">Создать</Link>
                                </div>
                            )
                        }
                    </div>
                </div>
            ):""
        }

        {
            popupType != "" ? (
                <div className="popup-msg">
                    <div className="popup-msg__body">
                        <h3>{popupType}</h3>
                        <div className="popup-msg__link" onClick={()=>{
                            setPopupType("")
                        }}>
                            Продолжить
                        </div>
                    </div>
                </div>
            ):""
        }
        </>
    )
}
