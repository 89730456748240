import React, {
    FC,
    ReactNode,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import classNames from 'classnames';
import styles from './Modal.module.css';
import Portal from "../Portal/Portal";

type ModalProps = {
    className?: string;
    isHeader?: boolean;
    headerText?: string;
    children?: ReactNode;
    isOpen?: boolean;
    onClose?: () => void;
};

const Modal: FC<ModalProps> = ({
                                   className,
                                   children,
                                   isOpen,
                                   onClose,
                                   isHeader = false,
                                   headerText,
                               }) => {
    const ANIMATION_DELAY = 300;
    const [isClosing, setIsClosing] = useState(false);
    const timerRef = useRef<ReturnType<typeof setTimeout>>();

    const closeHandler = useCallback(() => {
        if (onClose) {
            setIsClosing(true);
            timerRef.current = setTimeout(() => {
                onClose();
                setIsClosing(false);
            }, ANIMATION_DELAY);
        }
    }, [onClose]);

    useEffect(
        () => () => {
            clearTimeout(timerRef.current);
        },
        [isOpen],
    );

    const onContentClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };
    return (
        <Portal>
            <div
                className={classNames(
                    styles.modal,
                    {
                        [styles.opened]: isOpen,
                        [styles.isClosing]: isClosing,
                    },
                    [className],
                )}
            >
                <div className={styles.overlay} onClick={closeHandler}>
                    <div className={styles.content} onClick={onContentClick}>
                        {children}
                    </div>
                </div>
            </div>
        </Portal>
    );
};

export default Modal;
